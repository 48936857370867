import styled from 'styled-components';

export const FormStyled = styled.div`
    >.container{
        display: flex;
        flex-direction: column;
        border: 0;
        padding: 0!important;

        .error_change_password{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
                background-color: rgba(255,255,255,0.6);
                .inner_error_change_password{
                    display: flex;
                    flex-direction: column;
                    white-space: pre-line;
                    background-color: #FFFFFF;
                    max-width: 600px;
                    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
                    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
                    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
                    border-radius: 8px;
                    box-sizing: border-box;
                    padding: 10px 10px;
                    font-size: 14px;
                    line-height: 21px;
                    .close_error_change_password{
                        display: flex;
                        align-self: flex-end;
                        font-size: 12px!important;
                        line-height: 12px!important;
                        height: 30px!important;
                        padding: 0 15px!important;
                        margin-top: 10px;
                    }
                }
            }

        .actions{
            display: flex;
            justify-content: flex-end;
            width: 100%;
            margin-top: 15px;
        }
        >.inner{
            >.field_complex{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
        }
    }
`

export const LoaderStyled = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 0;
`