import React, { Fragment, useEffect, useState, useContext } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { FormStyled, LoaderStyled } from './styles';

import { CircularProgress } from '../../Atoms/CircularProgress';
import { Snackbar } from '../../Atoms/Snackbar';

import { ContainerGrid } from '../../Atoms/Container/ContainerGrid';
import { FormStructure } from '../FormStructure';
import { ContentContext } from '../../../context/ContentContext';
import { ButtonCustom } from '../../ButtonCustom';


const FormChangePassword = ({actionType, 
                      dataToEdit, 
                      setDataToEdit, 
                      actionForm, 
                      setActionForm,
                      monthSelected,
                      filtersData, 
                      setFiltersData }) => {
    
                
    const { cashflow, 
            setCashflow,
            editCashflow,
            createCashflow,
            clients,
            changePassword } = useContext(ContentContext)

    const [ formData, setFormData ] = useState({});
    const [ snackbarOpen, setSnackbarOpen ] = useState(false);
    const [ snackbarData, setSnackbarData ] = useState({text: '', severity: ''});
    const [ isLoading, setIsLoading ] = useState(false);
    const [ changePasswordErrors, setChangePasswordErrors ] = useState(null);
    
    useEffect(() => {
        //if(filtersData){
            
            const data = {
                current_password: {
                    isSlave: false,
                    masterOf: null,
                    standalone: false,
                    field: {
                        icon: { component: null, size: '' },
                        placeholder: 'Enter a text...',
                        icon: { component: null },
                        size: 'medium',
                        type: 'primary',
                        options: clients,
                        type: "password",
                    },
                    label: {
                        text: 'Current Password',
                        type: 'form',
                        reverse: false,
                        icon: { component: null }
                    },
                    value: dataToEdit?.user_id ? dataToEdit.user_id : '',
                    mandatory: true,
                    type: 'input:text',
                    error: false,
                    onChange: handleOnChange,
                    fullwidth: true,
                    innerElement: null,
                    dependendComponent: null,
                    errorMessage: null,
                    disableElements: null,
                },
                password: {
                    standalone: false,
                    field: {
                        icon: { component: null, size: '' },
                        placeholder: 'Enter a text...',
                        type: "password",
                        hint: <><strong>The password must contain:</strong><br/>- At least one letter<br/>- At least one uppercase and one lowercase letter<br/>- At least one number<br/>- At least one symbol</>
                    },
                    label: {
                        text: 'New Password',
                        type: 'form',
                        reverse: false,
                        icon: { component: null }
                    },
                    value: dataToEdit?.concept ? dataToEdit.concept : '',
                    mandatory: true,
                    type: 'input:text',
                    error: false,
                    onChange: handleOnChange,
                    fullwidth: true,
                    disabled: false,
                    //enabledBy: null,
                    errorMessage: null,
                    
                },
                repeat_password: {
                    standalone: false,
                    field: {
                        icon: { component: null, size: '' },
                        placeholder: 'Enter a text...',
                        type: "password",
                    },
                    label: {
                        text: 'Repeat Password',
                        type: 'form',
                        reverse: false,
                        icon: { component: null }
                    },
                    value: dataToEdit?.date ? dayjs(dataToEdit.date).format("DD/MM/YYYY") : '',
                    mandatory: true,
                    type: 'input:text',
                    error: false,
                    onChange: handleOnChange,
                    fullwidth: true,
                    disabled: false,
                    //enabledBy: null,
                    errorMessage: null,
                },
            }

            setFormData(data);
        //}
    
      return () => {
        setFormData({});
      }
    }, [dataToEdit, clients])//filtersData

    const handleCancel = () => {
        resetForm();
    }

    const handleOnChange = (e, name) => {
        
        const update = {...formData}



        if(name !== 'quantity' && name !== 'value' && name !== 'amount' && name !== 'balance'){
            update[name].value = e.target.value;
            if(e.target.value.length){
                update[name].error = false;
            }
        }else{
            update[name].value = e;
            if(e?.length){
                update[name].error = false;
            }
        }

        setFormData(update)

    }

    const handleSendForm = () => {
        
        setIsLoading(true);
        let haveError = false;

        let formDataValidate = {...formData};
        Object.keys(formDataValidate).map((field) => {
            
            if(formDataValidate[field].mandatory && field !== 'empty' && !formDataValidate[field].disabled){
                
                if(!formDataValidate[field].value.toString().length){
                    haveError = true;
                    formDataValidate[field].error =  true;
                }else{
                    formDataValidate[field].error =  false;
                }
            }
            
        })
        setFormData(formDataValidate);

       

        if(!haveError){

            const payload = {"current_password": formData["current_password"].value, 
                             "password": formData["password"].value, 
                             "repeat_password": formData["repeat_password"].value };

            const result = changePassword(payload);
            
            result.then(res => {
                
                setIsLoading(false);
                if(res.data.success){
                    setSnackbarData({text: 'Your password was successfully updated.', severity: 'success'});
                    setSnackbarOpen(true)
                }else{
                    let message_arr = [];

                    if("password" in res.data.errors){
                        for (let index = 0; index < res.data.errors.password.length; index++) {
                            message_arr.push(`- ${res.data.errors.password[index]}\n`);
                        }
                    }

                    if("repeat_password" in res.data.errors){
                        for (let index = 0; index < res.data.errors.repeat_password.length; index++) {
                            message_arr.push(`- ${res.data.errors.repeat_password[index]}\n`);
                        }
                    }

                    if("current_password" in res.data.errors){

                        message_arr.push(`- ${res.data.errors.current_password}`);
                        /*
                        for (let index = 0; index < res.data.errors.current_password.length; index++) {
                            message_arr.push(`- ${res.data.errors.current_password[index]}\n`);
                        }
                        */
                    }
                    

                    setChangePasswordErrors(message_arr);
                    //console.log("🚀", res.data.errors.password.length)
                }


                
                setIsLoading(false);
                if(res.data.success){
                    resetForm();
                }
                /*
                let data_cashflow = {user_id: res.data.record.user_id,
                                     current_password: res.data.record.created_at,
                                     password: res.data.record.deleted_at,
                                     repeat_password: res.data.record.date};
                                    
                if(dataToEdit){
                    const cashflowUpdated = cashflow.map(record => {
                        if(record.id === res.data.record.id){
                            return data_cashflow;//res.data.record;
                        }else{
                            return record;
                        }
                    })
                    setCashflow(cashflowUpdated);
                }else{
                    let cashflowUpdated = [...cashflow];
                    cashflowUpdated.push(data_cashflow);
                    setCashflow(cashflowUpdated);
                }

                if(!dataToEdit){
                    resetForm();
                }
                */
            })

        }else{
            setIsLoading(false);
            return null;
        }
        
    }

    const resetForm = () => {

        let formReset = {...formData};
        Object.keys(formReset).map((field) => {
            formReset[field].value = "";
            formReset[field].error = false;
        })
        setFormData(formReset);

    }

    const handleCloseChangeError = () => {
        setChangePasswordErrors(null);
    }

    useEffect(() => {
        //console.log("clients", clients)
    
      return () => {}
    }, [clients])
    
    useEffect(() => {
      return () => {
        setDataToEdit(null);
        setActionForm('');
        resetForm();
      }
    }, [])
    
    if(!clients){
        return null;
    }

    return (
        <FormStyled>
            <Snackbar 
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                snackbarData={snackbarData}
                setSnackbarData={setSnackbarData}
                hide={2500}
            />
            <ContainerGrid
                className={`container`}
                margins={{top: 0}}
            > 

                {changePasswordErrors ? 
                <div className='error_change_password'>
                    <div className='inner_error_change_password'>
                        {changePasswordErrors}
                        <ButtonCustom 
                            label="CLOSE"
                            className="close_error_change_password"
                            onClick={handleCloseChangeError}
                        />
                    </div>
                </div> : ""}

                <FormStructure 
                    formData={formData}
                    setFormData={setFormData}
                    handleOnChange={handleOnChange}
                    handleSendForm={handleSendForm}
                    handleCancel={handleCancel}
                    dataToEdit={dataToEdit}
                    isloading={isLoading}
                    payButton={false}
                    withActions={false}
                />

                <div className='actions'>
                    {!isLoading ?
                        <ButtonCustom 
                            label="SAVE"
                            className="see_detailed"
                            onClick={handleSendForm}
                        />
                    :
                    <LoaderStyled>
                        <CircularProgress 
                            type={'primary'} 
                            size={'medium'}
                        />
                    </LoaderStyled>
                    }
                </div>

            </ContainerGrid>
        </FormStyled>
    );
};

export default FormChangePassword;