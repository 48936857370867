import styled from 'styled-components';
import { breakpoints } from '../../helpers/breakpoints';
import { colors } from '../../helpers/commons';

export const WrapperStyled = styled.div`
    .boxes{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .box{
            background-color: #FFFFFF;
            width: 100%;
            border: 1px solid #e5e5e5;
            .top{
                display: flex;
                padding: 8px 15px;
                background-color: #e5e5e5;
                
            }
            &.password{
                margin-bottom: 20px;
                .top{
                    justify-content: flex-start;
                    .date_range{
                        display: flex;
                        align-items: center;
                        height: 30px;
                        font-size: 14px;
                        line-height: 14px;
                        color: #4d4d4d;
                    }
                }
            }
            .bottom{
                position: relative;
                padding: 20px 15px;
            }
            
        }
    }
    @media ${breakpoints.xs} {//576px

        .boxes{
            .box{
                .bottom{
                    
                }
                
            }
        }

    }
    @media ${breakpoints.sm} {//768px
        
    }
    @media ${breakpoints.md} {//992px
        .boxes{
            flex-direction: row;
            .box{
                width: 100%;//calc(50% - 15px);
                &.password{
                    margin-bottom: 0;
                }
            }
        }
    }
    @media ${breakpoints.lg} {//1200px // Desktop
        
    }
    @media ${breakpoints.xl} {//1366px
    }
    @media ${breakpoints.xxl} {//1440px
    }
    @media ${breakpoints.xxxl} {//1680px
    }
    @media ${breakpoints.xxxxl} {//1920px
    }
`
