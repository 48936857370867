import React, { useContext, useEffect } from 'react';
import { MdOutlineLogout } from "react-icons/md";
import { HeaderStyled } from './styles';
import { AuthContext, ContentContext } from '../../context';
import { useNavigate, useLocation } from 'react-router-dom';
    

const Header = props => {

    const navigate = useNavigate();
    const location = useLocation();

    const { logout, user, setUser } = useContext(AuthContext);
    const { setMenu } = useContext(ContentContext);

    const handleLogout = () => {
        setMenu({});
        setUser({});
        logout();

    }

    const handleGoToProfile = () => {
        navigate(`profile`);
    }

    return (
        <HeaderStyled>
            <div className='logo'>
                <img src={"/images/logo.svg"} alt='' />
            </div>
            <div className='logo_mobile'>
                <img src={"/images/logo_iso.svg"} alt='' />
            </div>
            <div className='welcome'>
                <div className='salutation'>Bienvenido, <div className='bt_goto_profile' onClick={handleGoToProfile}><strong>{user?.name}</strong></div></div>
                <div className='bt_logout' onClick={handleLogout}><MdOutlineLogout /></div>
            </div>

        </HeaderStyled>
    );
};

export default Header;