import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../../helpers/commons';

const WrapperInput = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    small{
        margin-top: 3px;
    }
`
const InputStyled = styled.input`
    width: 100%;
    font-family: "IBM Plex Sans",-apple-system,BlinkMacSystemFont,Roboto,Arial,sans-serif!important;
    border-radius: 6px;
    
    background-color: #FBFCFF;
    color: #879AC6;
    font-size: 15px;
    outline: 0;
    border: 1px solid #CADAFF;
    &.small{
        padding: 10px 10px;
        font-size: 12px;
    }
    &.medium{
        padding: 15px 15px;
        font-size: 15px;
    }
    &.large{
        padding: 15px 15px;
        font-size: 18px;
    }
    &.important{
        color: ${props => `rgba(${props.colors.important}, 1)` };
    }
    &.cancel{
        color: ${props => `rgba(${props.colors.cancel}, 1)` };
    }
    &.accept{
        color: ${props => `rgba(${props.colors.accept}, 1)` };
    }
    &.default{
        color: ${props => `rgba(${props.colors.default}, 1)` };
    }
    &.primary{
        color: ${props => `rgba(${props.colors.primary}, 1)` };
    }
    &.error{
        border-color: ${props => `rgba(${props.colors.error}, 1)` };
    }
    &::placeholder{
        color: ${props => `rgba(${props.colors.primary}, 1)` };
    }
    &::-ms-input-placeholder{
        color: ${props => `rgba(${props.colors.primary}, 1)` };
    }
    &:disabled{
        opacity: 0.5;
    }
`

export const Input = ({ className, 
                        error,
                        type = "text",
                        size, 
                        name, 
                        placeholder, 
                        icon, 
                        reverse, 
                        iconOutside, 
                        value,
                        setValue,
                        onChange,
                        disabled,
                        input_type,
                        hint,
                        ...props }) => {
    
    const _name = name.lenght ? 'input-'+name : '';
    
    return (
        <WrapperInput>
            <InputStyled
                disabled={disabled}
                colors={colors}
                name={name}
                type={type}
                placeholder={placeholder}
                className={`${className} ${_name} ${type} ${size} ${error ? 'error' : ''}`}
                value={value}
                onChange={(e) => onChange(e, name)}
                {...props}
            />
            {hint ? <small>{hint}</small> : "" }
        </WrapperInput>
    );
};

Input.propTypes = {
    type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
    size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
    name: PropTypes.string,
    placeholder: PropTypes.string,
    icon: PropTypes.element,
    className: PropTypes.string,
    error: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,//.isRequired,
    setValue: PropTypes.func,//.isRequired,
    disabled: PropTypes.bool,
};

Input.defaultProps = {
    type: 'primary',
    size: 'medium',
    name: '',
    placeholder: '',
    className: "input-text",
    error: false,
    disabled: false,
};