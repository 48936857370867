import React, { useState, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import { path } from 'ramda';
import { v4 as uuid } from 'uuid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { WrapperStyled,
         LoaderStyled } from './styles';
import { FieldSelect } from '../../../Molecules/FieldSelect';
import { Typography } from '../../../Atoms/Typography/Typography';
import { ButtonText } from '../../../Atoms/ButtonText/ButtonText';
import { TableHeader } from '../../../Molecules/TableHeader';
import { FieldCheckbox } from '../../../Molecules/FieldCheckbox';
import { FieldDateRange } from '../../../Molecules/FieldDateRange';
import { TableBody } from '../../../Molecules/TableBody';
import { Modal as ModalDelete } from '../../../Molecules/Modal';
import { FieldPayment } from '../../../Molecules/FieldPayment';
import { ButtonIcon } from '../../../Atoms/ButtonIcon/ButtonIcon';
import { Button } from '../../../Atoms/Button/Button';
import { ContentContext } from '../../../../context';
import { Badge } from '../../../Atoms/Badge';
import { CircularProgress } from '../../../Atoms/CircularProgress';
import { FaPlus, FaEye, FaCheck } from "react-icons/fa6";
import { FaPencilAlt, FaRegClock, FaRegCalendarAlt, FaRegCheckCircle, FaRegTrashAlt } from "react-icons/fa";
import { IoInfiniteSharp } from "react-icons/io5";
import { FaFilter } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";

import { get_client_name,
         number_format,  } from '../../../../helpers/commons';

var updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(updateLocale)

const Performance = (props) => {

    const { margins,
            colors,
            actionForm,
            //rowsSelected,
            //rowsSelectedAll,
            //handleCheckAllRow,
            //handleDeleteModal,
            handleDrawer,
            //handleCheckRow,
            handleEditOutcomeModal,
            dataToEdit, 
            setDataToEdit,
            filtersData,
            setFiltersData } = props;

            

    const { performance, 
            setPerformance,
            editPerformance,
            createPerformance,
            getPerformance,
            deletePerformance,
            clients } = useContext(ContentContext);

    const [ modalTitle, setModalTitle ] = useState("");
    const [ bulkPaymentComponent, setBulkPaymentComponent ] = useState(null);
    const [ months, setMonths ] = useState([]);
    const [ monthSelected, setMonthSelected ] = useState("");
    const [ rangeValue, setRangeValue ] = useState([])
    const [ rangeLoading, setRangeLoading ] = useState(false)
    const [ rowsSelected, setRowsSelected] = useState([]);
    const [ rowsSelectedAll, setRowsSelectedAll ] = useState(false);
    const [ singleRowsSelected, setSingleRowsSelected] = useState(null);
    const [ deleteComponent, setDeleteComponent ] = useState(null);
    const [ openDeleteModal, setOpenDeleteModal ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ snackbarOpen, setSnackbarOpen ] = useState(false);
    const [ snackbarData, setSnackbarData ] = useState({text: '', severity: ''});

    const [ filter, setFilter ] = useState({client: "", 
                                            concept: "", 
                                            start_date: "", 
                                            end_date: ""})

    const upper1366px = useMediaQuery('(min-width:1366px)');

    const columns = [{type: "checkbox", name: "check", label: '', orderable: false},
                     {type: "text", name: "client", label: "Client", orderable: false},
                     {type: "text", name: "amount", label: "Amount", orderable: false},
                     {type: "text", name: "created_at", label: "Date", orderable: false},
                     {type: "text", name: "actions", label: "Actions", orderable: false}];   
                    
                     
    const getItemDescription = (row) => {
        
        let description = "";
        /*
        description = `${get_element_product_decription(row, products, units)}`;
        */
        /*
        switch (row.expense_type.type) {
            case "administration":
                description = `${get_element_food_decription(row, products, suppliers)}`;
                break;
            case "food":
                description = `${get_element_food_decription(row, products, suppliers)}`;
                break;
            case "logistic":
                //description = get_element_logistic_decription(row, suppliers);
                description = `${get_element_food_decription(row, products, suppliers)}`;
                break;
            case "fixes":
                description = `${get_element_food_decription(row, products, suppliers)}`;
                break;
            case "miscellaneous":
                description = `${get_element_miscellaneous_decription(row, items)}`;
                break;
            default:
                break;
        }
        */
        return description;
        //row.expense_type.type === "food" ? `${get_element_food_decription(row, products, suppliers)}` : row.description

    }   
    /*
    const handleOnChangeMonth = (e) => {
        setMonthSelected(e.target.value);
        filter_by_start_month(portfolio, e.target.value);
    }
    */
    const handleOnChangeFilter = (e, name) => {
        const filter_update = {...filter};
        filter_update[name] = e.target.value;
        setFilter(filter_update);
    }

    const handleFilter = () => {
        setPerformance(null);
        //getPortfolio(filter);

        const response = getPerformance(filter);
        response.then(res => {
            let records = path(['data', 'records'], res);
            setPerformance(records);
        })

    }

    const handleClean = () => {
        setRangeValue([]);
    }

    const handleCheckRow = (e, row) => {
        //console.log(e, row);
        if(!rowsSelected.includes(row.id)){
            setRowsSelected(prev => [row.id, ...prev]);
        }else{

            setRowsSelectedAll(false);
            const rowsSelectedUpdate = rowsSelected.filter(el => el !== row.id);
            setRowsSelected(rowsSelectedUpdate);
        }

    }

    const handleCheckAllRow = (e, rows) => {
        
        if(e.target.checked){
            setRowsSelectedAll(true);
            const rows_id = rows.map(row => {
                return row.id;
            })
            setRowsSelected(rows_id);
        }else{
            setRowsSelectedAll(false);
            setRowsSelected([]);
        }

    }

    const handleEdit = (row) => {

        setDataToEdit(row);
        //console.log("row", row)

    }

    const handleDeleteModal = (type, row) => {

        //console.log(type, row);
        //return null;
        
        setModalTitle('Delete');

        if(type === 'bulk'){
            setSingleRowsSelected(null);
            setDeleteComponent(handleDeleteComponent(null));
        }
        if(type === 'single'){
            setRowsSelected([]);
            setSingleRowsSelected({id:row.id});
            setDeleteComponent(handleDeleteComponent(row.id, row));
        }
        
        setOpenDeleteModal(!openDeleteModal);
        
    }

    const handleDeleteComponent = (row_id, row) => {
        let text = '';
        
        if(rowsSelected.length){
            text = (<>You are about to <span className='highlight'>delete {rowsSelected.length} records</span>, do you want to continue?</>);
        }
        if(row_id){
            const description = getItemDescription(row);
            text = (<>You are about to delete the record <span className='highlight'>{ row.concept }</span>, do you want to continue?</>);
        }

        return(
            <div>
                {text}
            </div>
        )

    }

    const handleDelete = () => {

        setIsLoading(true);
                
        let response;
        let payload = {};

        payload = {id: rowsSelected.length ? rowsSelected : singleRowsSelected, bulk: rowsSelected.length ? true : false};
        response = deletePerformance(payload);

        response.then(res => {

            let result = [];
            let snackbarDataText = '';
            let snackbarDataSeverity = 'success';

            let performanceRemove = performance ? [...performance] : [];

            if(rowsSelected.length){

                snackbarDataText = 'Los datos se eliminaron con éxito';
                for (let index = 0; index < rowsSelected.length; index++) {
                    performanceRemove = performanceRemove.filter(el => el.id !== rowsSelected[index]);
                }
                setPerformance(performanceRemove);

            }

            if(singleRowsSelected){
                
                snackbarDataText = 'El dato se eliminó con éxito';
                performanceRemove = performanceRemove.filter(el => el.id !== singleRowsSelected.id);
                setPerformance(performanceRemove);

            }

            setSnackbarData({text: snackbarDataText, severity: snackbarDataSeverity});
            setSnackbarOpen(true)
            setIsLoading(false);
            
            setRowsSelected([]);
            setOpenDeleteModal(false);
            setRowsSelectedAll(false);
            
        })
        
    }

    useEffect(() => {
        
        const filter_update = {...filter};
        if(rangeValue.length){
            filter_update.start_date = dayjs(rangeValue[0]).format("DD/MM/YYYY"); 
            filter_update.end_date = dayjs(rangeValue[1]).format("DD/MM/YYYY");
        }else{
            filter_update.start_date = ""; 
            filter_update.end_date = ""; 
        }
        setFilter(filter_update);
    
      return () => {}
    }, [rangeValue])

    
    useEffect(() => {

        const response = getPerformance();
        response.then(res => {
            let records = path(['data', 'records'], res);
            let filters = path(['data', 'filters'], res);
            setPerformance(records);
            setFiltersData(filters);
        })

        return () => {
            setPerformance(null);
        }
    }, [])
    

    
    /*
    if(!cashflow){
        return (
            <LoaderStyled>
                <CircularProgress 
                    type={'primary'} 
                    size={'medium'}
                />
            </LoaderStyled>
        );
    }
    */
    
    return (
        <WrapperStyled
            margins={margins}
            colors={colors}
        >
            
            <ModalDelete 
                data={rowsSelected}
                title={modalTitle}
                component={deleteComponent}
                setComponent={setDeleteComponent}
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
                handleSuccess={handleDelete}
                isloading={isLoading}
            />

            {actionForm.length ? <div className='block'></div> : "" }

            {/* <FieldSelect 
                className='month_select'
                margins={{top: 3, bottom: 3}}
                paddings={null}
                select={{
                    uuid: uuid(),
                    icon: { uuid: uuid(),
                            component: <FaRegCalendarAlt />, 
                            type: 'primary', 
                            size: 'large' },
                    size: 'medium',
                    type: 'primary',
                    options: months,
                    selected: monthSelected
                }}
                label={{
                    text: 'Filtrar por mes',
                    type: 'form',
                    reverse: false,
                    icon: { component: null }
                }}
                value={monthSelected}
                name={'item_type'}
                error={false}
                onChange={handleOnChangeMonth}
            /> */}
            
            <div className='filter_box'>
                <div className='inner'>
                    <div className='filter_options'>

                        <FieldSelect 
                            className='field_filter client'
                            margins={{top: 3, bottom: 3}}
                            paddings={null}
                            select={{
                                uuid: uuid(),
                                icon: { uuid: uuid(),
                                        component: null, 
                                        type: 'primary', 
                                        size: 'large' },
                                size: 'small',
                                type: 'primary',
                                options: filtersData?.clients,
                                selected: filter.client
                            }}
                            label={{
                                text: 'Client',
                                type: 'form',
                                reverse: false,
                                icon: { component: null }
                            }}
                            value={filter.client}
                            name={'client'}
                            error={false}
                            onChange={handleOnChangeFilter}
                        />

                        <FieldDateRange 
                            className={"date_range"}
                            input={{
                                icon: { component: null, size: 'small' },
                                placeholder: "Select a range",
                            }}
                            label={{
                                text: "Filter by range of dates",
                                type: 'form',
                                reverse: false,
                                icon: { component: null }
                            }}
                            name={"date_range"}
                            error={false}
                            rangeValue={rangeValue}
                            setRangeValue={setRangeValue}
                            rangeLoading={rangeLoading}
                            setRangeLoading={setRangeLoading}
                            placement={"auto"}
                            handleClean={handleClean}
                        />

                    </div>
                    
                    <Button 
                        className={`bt_filter`}
                        type={`accept`}
                        size={`small`}
                        icon={<FaFilter />}
                        label={`Filter`}
                        margins={{top: 0, bottom: 0}}
                        onClick={() => handleFilter()}
                    /> 

                </div>
                <div className='filter_list'></div>
            </div>

            {rowsSelected && rowsSelected.length > 1 ?
                <div style={{display: "flex", flexDirection: "row", marginTop: "15px", marginBottom: "10px", marginRight: "auto" }}>
                    <Typography
                        _key={`bulk_delete_title`}
                        className={`table_bulk_actions`}
                        variant={`h4`}
                        type={`primary`}
                        margins={{right: 2}}
                    >
                        {rowsSelected.length} elementos seleccionados
                    </Typography>
                    <ButtonText
                        _key={`bulk_delete`}
                        type="cancel"
                        size="medium"
                        label={`Delete`}
                        margins={{right: 2}}
                        onClick={() => handleDeleteModal('bulk', null)}
                        icon={<FaRegTrashAlt />}
                    /> 
                </div> : ""
            }

            {!performance ?
            <LoaderStyled>
                <CircularProgress 
                    type={'primary'} 
                    size={'medium'}
                />
            </LoaderStyled>
            :
            <div className='table_box'>
                <div className='inner'>
                    <TableHeader
                        className={`table_header`}
                    >

                        {columns.map((column, index) => {
                            if(column.type === "checkbox"){ 
                                return(
                                    <div className='column check'>
                                        <FieldCheckbox
                                            _key={`check-table-head-${index}`}
                                            input={{ type:'accept', size: 'xsmall', reverse: false, icon:{ size: 'medium', component: null }}}
                                            label={null}
                                            checked={rowsSelectedAll}
                                            onChange={(e) => handleCheckAllRow(e, performance)}
                                        />
                                    </div>
                                ) 
                            };
                        })}

                        {columns.map((column, index) => {

                            if(column.type === "checkbox"){ return null };

                            return(
                                <div className={`column ${column.name}`}>
                                    <Typography
                                        className={`field_${column.name}`}
                                        variant={`h5`}
                                        type={`default`}
                                        margins={{top: 0}}
                                    >
                                        {column.label}
                                    </Typography>
                                </div>
                            )
                            
                        })}

                    </TableHeader>

                    <TableBody
                        className={`table_body`}
                    >

                        {performance !== null && !performance.length ?
                            <Typography
                                className={`no_result`}
                                variant={`h4`}
                                type={`default`}
                                margins={{top: 0}}
                                paddings={{top: 5, bottom: 5}}
                                textAlign={"center"}
                            >
                                There are no records to display.
                            </Typography> 
                        : ""}

                        {performance.map((row, index) => {
                            
                            //if(!row.visible){ return null };

                            //const p_status = payment_status(row);//payment_status(row, row.quotes.total, row.payments);

                            return(
                                <div key={`table-row-${index}`} className='table_row'>
                                    <div className='column check'>
                                        <FieldCheckbox
                                            input={{ type:'accept', size: 'xsmall', reverse: false, icon:{ size: 'medium', component: null }}}
                                            label={null}
                                            checked={rowsSelected.includes(row.id) ? true : false/*rowsSelected.some(r => r.id === row.id) */}
                                            onChange={(e) => handleCheckRow(e, row)}
                                        />
                                    </div>
                                    
                                    <div className='column client'>
                                        <Typography
                                            _key={`table-row-client-${index}`}
                                            className={`field_name`}
                                            variant={`${upper1366px ? "h4" : "h6"}`}
                                            type={`default`}
                                            margins={{top: 0}}
                                        >
                                            
                                            { get_client_name(clients, row.user_id) }
                                                
                                        </Typography>
                                    </div>
                                     
                                    <div className='column amount'>
                                        <Typography
                                            _key={`table-row-amount-${index}`}
                                            className={`field_name`}
                                            variant={`${upper1366px ? "h4" : "h6"}`}
                                            type={`default`}
                                            margins={{top: 0}}
                                        >
                                            
                                            { row.amount }
                                                
                                        </Typography>
                                    </div>

                                    <div className='column created_at'>
                                        <Typography
                                            _key={`table-row-description-${index}`}
                                            className={`field_name`}
                                            variant={`${upper1366px ? "h4" : "h6"}`}
                                            type={`default`}
                                            margins={{top: 0}}
                                        >
                                            {dayjs(row.date).format("DD/MM/YYYY")}
                                        </Typography>
                                    </div>

                                    <div className='column actions'>
                                        
                                        {/* <ButtonIcon 
                                            size={`medium`}
                                            type={`primary`}
                                            icon={<FaEye />}
                                            onClick={() => handleDrawer(row, 'data')}
                                        /> */}
                                        <ButtonIcon 
                                            size={`medium`}
                                            type={`primary`}
                                            icon={<FaPencilAlt />}
                                            onClick={() => handleEdit(row)}
                                        />
                                        <ButtonIcon 
                                            size={`medium`}
                                            type={`cancel`}
                                            icon={<FaRegTrashAlt />}
                                            onClick={() => handleDeleteModal('single', row)}
                                        />

                                    </div>
                                </div>
                            )
                        })}
                        
                    </TableBody>
                </div>
            </div>
            }


        </WrapperStyled>
    );
};

export default Performance;