import './App.css';

import React, { useContext, useEffect } from "react";
import * as ReactDOM from "react-dom/client";
import { ContentProvider, AuthContext, AuthProvider } from './context'; //AuthContext, 

import { createBrowserRouter,
         RouterProvider,
         redirect,
         useLocation } from "react-router-dom";

import Root from "./components/Layout/Root";
import { ErrorPage } from './pages/ErrorPage';
import { Dashboard } from './pages/Dashboard';
import { Login } from './pages/Login';
import { Portfolio } from './pages/Portfolio';
import { Performance } from './pages/Performance';
import { Transactions } from './pages/Transactions';
import { Cashflow } from './pages/Cashflow';
import { Benefits } from './pages/Benefits';
import { Profile } from './pages/Profile_';

import { Users as AdminUsers} from './pages/Admin/Users';
import { Portfolio as AdminPortfolio} from './pages/Admin/Portfolio';
import { DebitCard as AdminDebitCard } from './pages/Admin/DebitCard';
import { Cashflow as AdminCashflow } from './pages/Admin/Cashflow';
import { Dashboard as AdminDashboard } from './pages/Admin/Dashboard';
import { AccountDetail as AdminAccountDetail } from './pages/Admin/AccountDetail';
import { TrustFund as AdminTrustFund } from './pages/Admin/TrustFund';
import { Performance as AdminPerformance } from './pages/Admin/Performance';
 

import "./index.css";

function App() {

    const { isAuth, logout, user, setUser } = useContext(AuthContext);

    const protectedLoader = (back, path, type) => {
        //console.log("####", localStorage.getItem('is_admin'), path)

        if(type === "admin"){
            if(localStorage.getItem('is_admin') === "true"){
                //console.log("🔥", localStorage.getItem('is_admin'))
                //return redirect("/dashboard");
            }else{
                //console.log("💩", localStorage.getItem('is_admin'))
                return redirect("/dashboard");
            }
        }

        if(localStorage.getItem('token')){
            if(path === "/" || path === "login"){
                return redirect("/dashboard");
            }
        }
        const urlCompleta = window.location.href;
        const ruta = new URL(urlCompleta).pathname;
        if(!localStorage.getItem('token')){
            if(path !== "/" && path !== "login"){
                return redirect("/login");
            }
        }
        return null;

    }
    


    const setLogout = () => {
        setUser(null);
        logout();
    }
    
    

    const router = createBrowserRouter([
        {
            path: "/",
            element: <Root />,
            errorElement: <ErrorPage />,
            // loader(){
            //     return true;
            // },
            children: [
                {
                    path: "/",
                    loader: () => protectedLoader(null, "/"),
                    element: <Login />,
                },
                {
                    path: "/login",
                    //loader: () => protectedLoader(null, "login"),
                    element: <Login />,
                },
                {
                    path: "dashboard/",
                    loader: protectedLoader,
                    element: (localStorage.getItem('is_admin') !== "true" && isAuth) ? <Dashboard /> : null,
                },
                {
                    path: "profile/",
                    loader: protectedLoader,
                    element: (localStorage.getItem('is_admin') !== "true" && isAuth) ? <Profile /> : null,
                },
                {
                    path: "portfolio/",
                    loader: protectedLoader,
                    element: (localStorage.getItem('is_admin') !== "true" && isAuth) ? <Portfolio /> : null,
                },
                {
                    path: "performance/",
                    loader: protectedLoader,
                    element: (localStorage.getItem('is_admin') !== "true" && isAuth) ? <Performance /> : null,
                },
                {
                    path: "debit-cards/",
                    loader: protectedLoader,
                    element: (localStorage.getItem('is_admin') !== "true" && isAuth) ? <Transactions /> : null,
                },
                {
                    path: "cash-flow/",
                    loader: protectedLoader,
                    element: (localStorage.getItem('is_admin') !== "true" && isAuth) ? <Cashflow /> : null,
                },
                {
                    path: "benefits/",
                    loader: protectedLoader,
                    element: (localStorage.getItem('is_admin') !== "true" && isAuth) ? <Benefits /> : null,
                },
                {   
                    path: "/admin/dashboard/",
                    loader: () => protectedLoader(null, "/admin/dashboard/", "admin"),
                    element: (localStorage.getItem('is_admin') === "true" && isAuth) ? <AdminDashboard /> : null,
                },
                {   
                    path: "/admin/account-detail/",
                    loader: () => protectedLoader(null, "/admin/account-detail/", "admin"),
                    element: (localStorage.getItem('is_admin') === "true" && isAuth) ? <AdminAccountDetail /> : null,
                },
                {   
                    path: "/admin/trust-fund/",
                    loader: () => protectedLoader(null, "/admin/trust-fund/", "admin"),
                    element: (localStorage.getItem('is_admin') === "true" && isAuth) ? <AdminTrustFund /> : null,
                },
                {   
                    path: "/admin/performance/",
                    loader: () => protectedLoader(null, "/admin/performance/", "admin"),
                    element: (localStorage.getItem('is_admin') === "true" && isAuth) ? <AdminPerformance /> : null,
                },
                {
                    path: "/admin/users/",
                    loader: () => protectedLoader(null, "/admin/users/", "admin"),
                    element: (localStorage.getItem('is_admin') === "true" && isAuth) ? <AdminUsers /> : null,
                },
                {
                    path: "/admin/portfolio/",
                    loader: () => protectedLoader(null, "/admin/portfolio/", "admin"),
                    element: (localStorage.getItem('is_admin') === "true" && isAuth) ? <AdminPortfolio /> : null,
                },
                {
                    path: "/admin/debit-cards/",
                    loader: () => protectedLoader(null, "/admin/debit-cards/", "admin"),
                    element: (localStorage.getItem('is_admin') === "true" && isAuth) ? <AdminDebitCard /> : null,
                },
                {
                    path: "/admin/cash-flow/",
                    loader: () => protectedLoader(null, "/admin/cash-flow/", "admin"),
                    element: (localStorage.getItem('is_admin') === "true" && isAuth) ? <AdminCashflow /> : null,
                },

                {   loader: setLogout,
                    path: "/logout",
                },
            ]
        },
    ]);
    
    /*
    const routerAdmin = createBrowserRouter([
        {
            path: "/",
            element: <Root />,
            errorElement: <ErrorPage />,
            // loader(){
            //     return true;
            // },
            children: [
                {
                    path: "/",
                    loader: () => protectedAdminLoader(null, "/"),
                    element: <Login />,
                },
                {
                    path: "/login",
                    loader: () => protectedAdminLoader(null, "login"),
                    element: <Login />,
                },
                {   
                    path: "/admin/dashboard/",
                    loader: protectedAdminLoader,
                    element: <AdminDashboard />,
                },
                {
                    path: "users/",
                    loader: protectedAdminLoader,
                    element: <AdminUsers />,
                },
                {
                    path: "portfolio/",
                    loader: protectedAdminLoader,
                    element: <AdminPortfolio />,
                },
                {
                    path: "debit-cards/",
                    loader: protectedAdminLoader,
                    element: <AdminDebitCard />,
                },
                {
                    path: "cash-flow/",
                    loader: protectedAdminLoader,
                    element: <AdminCashflow />,
                },
                {   loader: setLogout,
                    path: "/logout",
                },
            ]
        },
    ]);
    */

    useEffect(() => {
        if(!localStorage.getItem('token')){
          redirect("/");
        }
      
        return () => {}
      }, [localStorage.getItem('token')])

    useEffect(() => {
        if(user){
            //console.log("user", user, isAuth)
            if(localStorage.getItem('token')){

                const urlCompleta = window.location.href;
                const ruta = new URL(urlCompleta).pathname;

                if(ruta === "/" && ruta === "/login"){

                }
                //console.log("pathname", ruta)
                /*
                if(path === "/" || path === "login"){
                    console.log("user.is_admin", user.is_admin)
                    if(user.is_admin){
                        return redirect("/admin/dashboard");
                    }else{
                        return redirect("/dashboard");
                    }
                }
                    */
            }

            if(user.is_admin){
                //console.log("-- user", user.is_admin)
            }else{
                //console.log("***")
            }
        }
      
    
      return () => {}
    }, [user])

    useEffect(() => {
      
    
      return () => {}
    }, [localStorage.getItem('is_admin')])
    
    
    if(isAuth === null && !Object.keys(user).length){
        return null;
    }    

    return (
        <>

            
            <ContentProvider>
                <RouterProvider router={router} />
                {/* {localStorage.getItem('is_admin') ?
                    localStorage.getItem('is_admin') ?
                    <RouterProvider router={routerAdmin} />
                : 
                    <RouterProvider router={router} />
                :""} */}
            </ContentProvider>
       

        

        </>

    );

}

export default App;


/*
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
*/
