import styled from 'styled-components';
import { breakpoints } from '../../helpers/breakpoints';
import { colors } from '../../helpers/commons';

export const WrapperStyled = styled.div`
    .boxes{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .box{
            background-color: #FFFFFF;
            width: 100%;
            border: 1px solid #e5e5e5;
            .top{
                display: flex;
                padding: 8px 15px;
                background-color: #e5e5e5;
                
            }
            &.performance{
                .top{
                    justify-content: flex-end;
                }
                .months{
                    display: flex;
                    flex-direction: row;
                    :last-child{
                        margin-right: 0!important;
                    }
                    .month{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 14px;
                        line-height: 14px;
                        font-weight: 500;
                        height: 30px;
                        width: 45px;
                        color: rgba(${colors.white}, 1);
                        background-color: rgba(${colors.darkGrey}, 1);
                        margin-right: 5px;
                        cursor: pointer;
                        &.selected{
                            background-color: rgba(${colors.darkBlue}, 1);
                        }
                        &:hover{
                            background-color: rgba(${colors.darkBlue}, 1);
                        }
                    }
                }
            }
            &.summary{
                margin-bottom: 20px;
                .top{
                    justify-content: flex-start;
                    .date_range{
                        display: flex;
                        align-items: center;
                        height: 30px;
                        font-size: 14px;
                        line-height: 14px;
                        color: #4d4d4d;
                    }
                }
            }
            .bottom{
                position: relative;
                padding: 20px 15px;
                >.title{
                    font-size: 22px;
                    line-height: 22px;
                    font-weight: 500;
                    color: rgba(${colors.darkBlue}, 1);
                }
                >.subtitle{
                    font-size: 15px;
                    line-height: 15px;
                    font-weight: 400;
                    margin-top: 2px;
                    color: rgba(${colors.darkBlue}, 1);
                }
                .items{
                    display: flex;
                    flex-direction: column;
                    margin-top: 40px;
                    color: rgba(${colors.darkBlue}, 1);
                    .item{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        padding: 8px 8px;
                        font-size: 15px;
                        font-weight: 500;
                        .label{
                            width: calc(100% - 90px);
                            //background-color: pink;
                        }
                        .value{
                            width: 110px;
                            text-align: left;
                            margin-left: 20px;
                            //background-color: skyblue;
                        }
                        &.grey{
                            background-color: rgba(${colors.lightGrey}, 1);
                        }
                    }
                }
                .return{
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    right: 15px;
                    top: 15px;
                    .return_value{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        .value{
                            display: flex;
                            align-items: flex-start;
                            font-size: 45px;
                            line-height: 45px;
                            font-weight: 500;
                            
                            .percent{
                                font-size: 25px;
                                line-height: 25px;
                                padding-top: 2px;
                            }
                        }
                        .icon{
                            display: flex;
                            font-size: 22px;
                            line-height: 22px;
                        }
                    }
                    .label{
                        font-size: 15px;
                        line-height: 15px;
                        font-weight: 500;
                        padding-left: 7px;
                        
                    }

                    &.red{
                        .label,
                        .return_value{
                            color: rgba(${colors.red}, 1);
                        }
                        
                    }
                    &.green{
                        .label,
                        .return_value{
                            color: rgba(${colors.green}, 1);
                        }
                    }
                }
                .value_range{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-top: 40px;
                    .beginning{
                        .title{

                        }
                        .inner_value{
                            margin-top: 10px;
                            .value{
                                font-size: 35px;
                                line-height: 35px;
                            }
                            .currency{
                                font-size: 16px;
                                line-height: 16px;
                            } 
                        }
                    }
                    .ending{
                        .title{

                        }
                        .inner_value{
                            margin-top: 10px;
                            .value{
                                font-size: 35px;
                                line-height: 35px;
                            }
                            .currency{
                                font-size: 16px;
                                line-height: 16px;
                            } 
                        }
                    }
                    .vertical_division{
                        width: 100%;
                        height: 2px;
                        margin-top: 15px;
                        margin-bottom: 15px;
                        background-color: rgba(${colors.lightGrey}, 1);
                    }
                }
                .actions{
                    margin-top: 30px;
                }
            }
            
        }
    }
    @media ${breakpoints.xs} {//576px

        .boxes{
            .box{
                .bottom{
                    .value_range{
                        flex-direction: row;
                        .vertical_division{
                            width: 2px;
                            height: 70px;
                            margin-top: 0;
                            margin-bottom: 0;
                        }
                    }
                    .actions{
                        margin-top: 30px;
                    }
                    .items{
                        display: flex;
                        flex-direction: column;
                        margin-top: 40px;
                        color: rgba(${colors.darkBlue}, 1);
                        .item{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            padding: 8px 8px;
                            font-size: 15px;
                            font-weight: 500;
                            .label{
                                width: calc(100% - 200px);
                            }
                            .value{
                                width: 180px;
                                text-align: left;
                                margin-left: 20px;
                            }
                            &.grey{
                                background-color: rgba(${colors.lightGrey}, 1);
                            }
                        }
                    }
                }
                
            }
        }

    }
    @media ${breakpoints.sm} {//768px
        
    }
    @media ${breakpoints.md} {//992px
        .boxes{
            flex-direction: row;
            .box{
                width: calc(50% - 15px);
                &.summary{
                    margin-bottom: 0;
                }
            }
        }
    }
    @media ${breakpoints.lg} {//1200px // Desktop
        
    }
    @media ${breakpoints.xl} {//1366px
    }
    @media ${breakpoints.xxl} {//1440px
    }
    @media ${breakpoints.xxxl} {//1680px
    }
    @media ${breakpoints.xxxxl} {//1920px
    }
`