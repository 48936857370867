import React, { useContext, useState, useEffect } from 'react';
import { createBrowserRouter,
         RouterProvider,
         redirect,
         useLocation } from "react-router-dom";
import { AuthContext,
         ContentContext } from '../../context';
import { WrapperStyled } from './styles';
import { Scrollbar } from 'react-scrollbars-custom';
import { CircularProgress } from '@mui/material';
import { number_format } from '../../helpers/commons';
import { FormChangePassword } from '../../components/Organisms/FormChangePassword';

const Profile = () => {

    const { get_user_debitcard } = useContext(ContentContext);

    const [ actionForm, setActionForm ] = useState("");
    const [ optionSelected, setOptionSelected ] = useState("");
    const [ dataToEdit, setDataToEdit ] = useState(null);
    const [ filtersData, setFiltersData ] = useState(null);





    return (
        <WrapperStyled>
            <div className='boxes'>
                <div className='box password'>
                    <div className='top'>
                        <div className='date_range'>Change Your Password</div>
                    </div>
                    <div className='bottom'>
                        <FormChangePassword
                            dataToEdit={dataToEdit}
                            setDataToEdit={setDataToEdit}
                            actionForm={actionForm}
                            setActionForm={setActionForm}
                            filtersData={filtersData}
                            setFiltersData={setFiltersData}
                        />
                    </div>
                </div>
                
            </div>
        </WrapperStyled>
    );
};

export default Profile;