import React, { useContext, useEffect, useState } from 'react';

import { v4 as uuid } from 'uuid';
import { GiSuperMushroom } from "react-icons/gi";
import { AiOutlineDollar } from "react-icons/ai";

import { ContentContext } from '../../../context/ContentContext';

import { FieldInput } from '../../Molecules/FieldInput';
import { FieldSelect } from '../../Molecules/FieldSelect';
import { FieldInputMasked } from '../../Molecules/FieldInputMasked';
import { FieldInputCurrency } from '../../Molecules/FieldInputCurrency';
import { FieldSwitch } from '../../Molecules/FieldSwitch';

import { FormElementStyled } from './styles';

import { ContainerSimple } from '../../Atoms/Container/ContainerSimple';
import { ActionsForm } from '../../Molecules/ActionsForm';
import { ContainerGrid } from '../../Atoms/Container/ContainerGrid';
import { OverlayWaiting } from '../../Atoms/OverlayWaiting';
import { CircularProgress } from '../../Atoms/CircularProgress';
//import { AddElementData } from '../AddElementData/AddElementData';
//import { Tags } from '../Tags';

const FormStructure = ({formData, 
                        setFormData, 
                        handleOnChange, 
                        handleSendForm, 
                        handleCancel, 
                        handleDeleteTag, 
                        withActions = true, 
                        dataToEdit, 
                        isloading, 
                        setSnackbarOpen, 
                        snackbarData, 
                        setSnackbarData,
                        payButton = true,}) => {

    
    const { setDataCategories,
            items,
            setItems,
            services, 
            setServices,
            suppliers, 
            setSuppliers,
            products,
            setProducts } = useContext(ContentContext);

    const [ isLoading, setIsLoading ] = useState(false);


    const getDropContent = (category) => {
        
        const variables = { items, products, suppliers };
        return variables[category];//[...variables[category]];

    }

    useEffect(() => {
      
        if(dataToEdit){

        }
    
      return () => {}
    }, [dataToEdit])
    
    
    return (
        
        <FormElementStyled>
            <div className='inner'>

                {Object.keys(formData).length ? 
                    Object.keys(formData).map((field) => {

                        if(formData[field].hide || formData[field].isSlave || formData[field].standalone){ return null; };
                        
                        let is_disabled = formData[field].disabled;

                        switch(formData[field].type){
                            case 'input:text':
                            case 'input:number':
                                return(
                                    <FieldInput 
                                        input={{
                                            uuid: formData[field].field.uuid,
                                            icon: { uuid: formData[field].field.icon.uuid, 
                                                    component: null, 
                                                    size: 'medium' },
                                            placeholder: formData[field].field.placeholder,
                                            type: formData[field].field?.type ? formData[field].field.type : "text",
                                            hint: formData[field].field?.hint ? formData[field].field.hint : null,
                                        }}
                                        label={{
                                            uuid: formData[field].label.uuid,
                                            text: formData[field].label.text,
                                            type: 'form',
                                            reverse: false,
                                            icon: { uuid: formData[field].label.icon.uuid, 
                                                    component: null }
                                        }}
                                        value={formData[field].value}
                                        prefix={formData[field].prefix}
                                        name={field}
                                        error={formData[field].error}
                                        onChange={handleOnChange}
                                        fullwidth={formData[field].fullwidth}
                                        disabled={formData[field].disabled}
                                        errorMessage={formData[field].errorMessage}
                                        help={formData[field]?.help ? formData[field].help : ""}
                                    />
                                )
                                break;
                            case 'input:masked':
                                return(
                                    <FieldInputMasked
                                        input={{
                                            uuid: formData[field].field.uuid,
                                            icon: { uuid: formData[field].field.icon.uuid, 
                                                    component: null, 
                                                    size: 'medium' },
                                            placeholder: formData[field].field.placeholder,
                                        }}
                                        label={{
                                            uuid: formData[field].label.uuid,
                                            text: formData[field].label.text,
                                            type: 'form',
                                            reverse: false,
                                            icon: { uuid: formData[field].label.icon.uuid, 
                                                    component: null }
                                        }}
                                        value={formData[field].value}
                                        name={field}
                                        error={formData[field].error}
                                        onChange={handleOnChange}
                                        fullwidth={formData[field].fullwidth}
                                        disabled={formData[field].disabled}
                                        errorMessage={formData[field].errorMessage}
                                        help={formData[field].help}
                                    />
                                )
                                break;   
                            case 'input:currency':
                                return(
                                    <FieldInputCurrency
                                        input={{
                                            uuid: formData[field].field.uuid, 
                                            icon: { uuid: formData[field].field.icon.uuid, 
                                                    component: null, 
                                                    size: 'medium' },
                                            placeholder: formData[field].field.placeholder,
                                            prefix: formData[field].prefix,
                                            suffix: "suffix" in formData[field] ? formData[field].suffix : "",
                                        }}
                                        label={{
                                            uuid: formData[field].label.uuid,
                                            text: formData[field].label.text,
                                            type: 'form',
                                            reverse: false,
                                            icon: { uuid: formData[field].label.icon.uuid, 
                                                    component: null }
                                        }}
                                        value={formData[field].value}
                                        name={field}
                                        error={formData[field].error}
                                        onChange={handleOnChange}
                                        fullwidth={formData[field].fullwidth}
                                        disabled={formData[field].disabled}
                                        errorMessage={formData[field].errorMessage}
                                        help={formData[field]?.help ? formData[field].help : ""}
                                    />
                                )
                                break; 
                            case 'select:common':
                                return(
                                    
                                    <div className={`field_complex${formData[field].fullwidth ? " fullwidth" : ""}`}>
                                    <FieldSelect 
                                        select={{
                                            uuid: formData[field].field.uuid,
                                            icon: { uuid: formData[field].field.icon.uuid, 
                                                    component: null },
                                            size: 'medium',
                                            type: 'primary',
                                            options: formData[field]?.innerElement ? getDropContent(formData[field].innerElement.category) : formData[field].field.options,
                                        }}
                                        label={{
                                            uuid: formData[field].label.uuid,
                                            text: formData[field].label.text,
                                            type: 'form',
                                            reverse: false,
                                            icon: { uuid: formData[field].field.icon.uuid, 
                                                    component: null }
                                        }}
                                        value={formData[field].value}
                                        name={field}
                                        error={formData[field].error}
                                        onChange={handleOnChange}
                                        fullwidth={formData[field].fullwidth}
                                        disabled={is_disabled}
                                        errorMessage={formData[field].errorMessage}
                                        help={formData[field]?.help ? formData[field].help : ""}
                                    />
                                    {/* {formData[field].innerElement ? 
                                        formData[field].innerElement.type === 'container:add' ?
                                        <AddElementData 
                                            key={uuid()}
                                            label={`${formData[field].innerElement.label}`}
                                            scope={formData[field].innerElement.scope}
                                            category={formData[field].innerElement.category}
                                            elementToModify={formData[field].innerElement.elementToModify}
                                            setSnackbarOpen={setSnackbarOpen}
                                            snackbarData={snackbarData}
                                            setSnackbarData={setSnackbarData}

                                        />
                                        :""
                                    : ""} */}

                                    {/* {formData[field]?.taggedList?.list.length ? 
                                        <Tags 
                                            tags={formData[field].taggedList.list}
                                            parentData={formData[field].taggedList.parentData}
                                            onDelete={handleDeleteTag}
                                        />
                                    : ""} */}

                                    </div>
                                    
                                )
                                break;
                            case 'input:switch':
                                return(
                                    <FieldSwitch

                                    //reverse= formData[field].reverse,
                                    _switch= {{
                                        type: formData[field]._switch.type,
                                        className: 'switch',
                                        error: false,
                                        size: 'medium',
                                    }}
                                    label= {{
                                        type: 'primary',
                                        text: formData[field].label.text,
                                        icon: {
                                            type: 'primary',
                                            component: formData[field].label.component,//<AiOutlineDollar />,
                                            size: 'label',
                                        },
                                        className: "",
                                        reverse: false,
                                        size: 'medium',
                                    }}
                                    name={field}
                                    active={formData[field].value}
                                    reverse={true}
                                    error={formData[field].error}
                                    mandatory={formData[field].mandatory}
                                    fullwidth={formData[field].fullwidth}
                                    disabled={formData[field].disabled}
                                    value={formData[field].value}
                                    onClick={handleOnChange}

                                        /*
                                        input={{
                                            uuid: formData[field].field.uuid,
                                            icon: { uuid: formData[field].field.icon.uuid, 
                                                    component: null, 
                                                    size: 'medium' },
                                            placeholder: formData[field].field.placeholder,
                                        }}
                                        label={{
                                            uuid: formData[field].label.uuid,
                                            text: formData[field].label.text,
                                            type: 'form',
                                            reverse: false,
                                            icon: { uuid: formData[field].label.icon.uuid, 
                                                    component: null }
                                        }}
                                        value={formData[field].value}
                                        prefix={formData[field].prefix}
                                        name={field}
                                        
                                        onChange={handleOnChange}
                                        fullwidth={formData[field].fullwidth}
                                        disabled={formData[field].disabled}
                                        errorMessage={formData[field].errorMessage}
                                        help={formData[field]?.help ? formData[field].help : ""}

                                        */
                                    />
                                )
                                break
                            case 'field:data':
                                return(
                                    <></>
                                )
                                break
                            default:
                                return(
                                    <div></div>
                                )
                        }
                        
                        
                    }) 
                : 
                ""}
                
            </div>
            {withActions &&
                <ActionsForm 
                    halign={`right`}
                    valign={`top`}
                    margins={{top: 5}}
                    isloading={isloading}
                    actions={
                        { 
                            save: {label: !dataToEdit ? "Save" : "Edit", type: "default", size: "medium", onClick: () => handleSendForm(false), snapTo: null},
                            cancel: {label: "Cancel", type: "cancel", size: "medium", margins:{left: 1}, onClick: handleCancel, snapTo: null}
                        }
                    }
                />
            }
        </FormElementStyled>
    );
};

export default FormStructure;