import styled from 'styled-components';
import { colors } from '../../helpers/commons';
import { breakpoints } from '../../helpers/breakpoints';

export const HeaderStyled = styled.header`
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    border-bottom: 1px solid rgba(${colors.lightBlue}, 1);
    background-color: rgba(${colors.brand}, 1);
    padding: 0 15px;
    z-index: 99;
    .salutation{
        display: flex;
        flex-direction: row;
        .bt_goto_profile{
            cursor: pointer;
            &:hover{
                color: rgba(${colors.red});
            }
        }
    }
    .logo{
        display: none;
        width: 250px;
        img{
            width: 100%;
            float: left;
        }
    }
    .logo_mobile{
        width: 25px;
        display: flex;
    }
    .welcome{
        display: flex;
        flex-direction: row;
        align-items: center;
        color: rgba(${colors.white}, 1);
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
        .user_name{
            font-weight: 600;
        }
        .bt_logout{
            display: flex;
            font-size: 18px;
            line-height: 18px;
            margin-left: 10px;
            cursor: pointer;
            &:hover{
                color: rgba(${colors.red});
            }
        }
    }

    @media ${breakpoints.xs} {//576px
    }
    @media ${breakpoints.sm} {//768px
        .logo{
            display: flex;
        }
        .logo_mobile{
            display: none;
        }
    }
    @media ${breakpoints.md} {//992px
    }
    @media ${breakpoints.lg} {//1200px // Desktop
    }
    @media ${breakpoints.xl} {//1366px
    }
    @media ${breakpoints.xxl} {//1440px
    }
    @media ${breakpoints.xxxl} {//1680px
    }
    @media ${breakpoints.xxxxl} {//1920px
    }
`